import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

import {WeekDay} from '@/types/utils';

import routes from './routes';

const CALENDAR_LICENSE_KEY = '0872470027-fcs-1689708676';

enum constants {
    USER = 'USER',
    PATIENTS = 'PATIENTS',
    NURSES = 'NURSES',
    PATIENT_PROFILE = 'PATIENT_PROFILE',
    ZOOM = 'ZOOM',
    DEVICES = 'DEVICES',
    KITS = 'KITS',
    KIT_PAGE = 'KIT_PAGE',
    COMMON_DATA = 'COMMON_DATA',
    ADMINISTRATE = 'ADMINISTRATE',
    MEDIA = 'MEDIA',
    ORDERS = 'ORDERS',
    ORDERSFIELDS = 'ORDERSFIELDS',
    ALARMS = 'ALARMS',
    APPOINTMENTS = 'APPOINTMENTS',
    CALLS_HISTORY = 'CALLS_HISTORY',
    VITAL_SIGNS = 'VITAL_SIGNS',
    SURVEYS = 'SURVEYS',
    APPOINTMENT_SCHEDULE_FLOW = 'APPOINTMENT_SCHEDULE_FLOW',
    TASKS = 'TASKS',
    ADMINISTRATE_TASKS = 'ADMINISTRATE_TASKS',
    CLUSTERS = 'CLUSTERS',
    PODS = 'PODS',
    LOGGED_IN_USERS = 'LOGGED_IN_USERS',
    LOCAL_911_TYPES = 'LOCAL_911_TYPES',
}

enum appointmentTypes {
    GENERAL_APPOINTMENT = 'General',
    IN_HOME_APPOINTMENT = 'In Home',
}

enum appointmentsStates {
    started = 'Started',
    completed = 'Completed',
    cancelled = 'Cancelled',
    removed = 'Removed',
    scheduled = 'Scheduled',
}

enum appointmentsStatus {
    SCHEDULED = 'Scheduled',
    CONFIRMED = 'Confirmed',
    CHECK_IN_STARTED = 'Check-in started',
    REVIEW_CHECK_IN = 'Check-in ready for review',
    REVIEW_CHECK_OUT = 'Check-out ready for review',
    CHECKED_IN = 'Checked In',
    CHECK_OUT_STARTED = 'Check-out started',
    CHECK_OUT = 'Checked Out',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
    PENDING_CANCELLATION = 'Pending Cancellation',
    NOT_FULFILLED = 'Not fulfilled',
    REMOVED = 'Removed',
    NOT_STARTED = 'Not started',
}

const EDIT_HIDDEN_STATUSES = ['Completed', 'Pending Cancellation', 'Cancelled'];

enum ACTIVITY_STATUSES {
    REVIEW_CHECK_IN = 'Check-in ready for review',
    REVIEW_CHECK_OUT = 'Check-out ready for review',
}

enum ACTIONS {
    CONFIRM = 'Confirm',
    CHECK_IN = 'Check in',
    CHECK_OUT = 'Check out',
    DOCUMENT = 'Document',
    REVIEW_CHECK_IN = 'Review Check-In',
    REVIEW_CHECK_OUT = 'Review Check-Out',
    SELECT_VENDOR = 'Select Vendor',
    CREATE_IMPROVEMENT_LOG_ENTRY = 'Create Improvement Log Entry',
}

enum BLOCKING_CODES {
    IN_CARE_PLAN = 'IN_CARE_PLAN',
}

enum surveysState {
    READY = 'Ready',
    STARTED = 'Started',
    COMPLETED = 'Complete',
    REVIEWED = 'Reviewed',
    EXPIRED = 'Expired',
}

enum DEFAULT_PAGINATION {
    count = 0,
    size = 10000,
    page = 0,
}

enum TASKS_TYPES {
    simple = 'simpleTask',
    standard = 'standardTask',
}

enum TASKS_STATUSES {
    OPEN = 'Open',
    STARTED = 'Started',
    DONE = 'Done',
    CANCELED = 'Cancelled',
}

enum DEVICE_STATUSES {
    READY_FOR_USE = 'Ready to use',
    NEEDS_INSPECTION = 'Needs inspection',
    RETIRED = 'Retired',
    ASSIGNED = 'Assigned',
    REMOVED_FROM_INSTANCE = 'Removed from instance',
}

enum STATUSES {
    ASSIGNED = 'ASSIGNED',
    READY_FOR_USE = 'READY_FOR_USE',
    INCOMPLETE = 'INCOMPLETE',
    REMOVED_FROM_INSTANCE = 'REMOVED_FROM_INSTANCE',
    RETIRED = 'RETIRED',
}

enum DEVICE_FILTER_TYPES {
    SERIAL_NUMBER = 'serial_number',
    ASSET_TAG = 'asset_tag',
    SERVICE_ACCOUNT = 'service_account',
}

enum DEFAULT_DEVICES_QUERY_PARAMS {
    sortingColumn = 'status',
    sortingOrder = '',
    searchString = '',
    searchFilter = '',
    homeLocationId = '',
}

enum FIELD_TYPES {
    SELECT_FIELD = 'SELECT_FIELD',
    TEXT_FIELD = 'TEXT_FIELD',
    DATE_FIELD = 'DATE_FIELD',
}

enum VENDOR_DICTIONARY_TYPES {
    VENDOR = 'VENDOR',
    SITE = 'SITE',
    ACTOR = 'ACTOR',
    ACTIVITY = 'ACTIVITY',
    NONE = 'NONE',
}

enum VENDOR_DICTIONARY_CONTACT_PRIORITY {
    Regular = 'Regular',
    Escalation = 'Escalation',
}

const KIT_ASSIGNMENT_STATUSES = {
    ANY: {label: 'All Devices', value: 'ANY'},
    ASSIGNED: {label: 'Devices in Kits', value: 'ASSIGNED'},
    NOT_ASSIGNED: {label: 'Devices Not in Kits', value: 'NOT_ASSIGNED'},
} as const;

enum themes {
    night = 'night',
    day = 'day',
}

enum ACTIVITY_STATES {
    COMPLETED = 'Completed',
    NEEDS_TO_BE_RESCHEDULED = 'Needs to be rescheduled',
    DO_NOT_RESCHEDULE = 'Do not reschedule',
}

const VENDOR_DICTIONARY_NEW_ID = 'new_id';
const VITAL_SIGNS_BLOOD_PRESSURE_TYPE = 'BLOOD_PRESSURE';
const VITAL_SIGNS_PERFUSION_TYPE = 'PERFUSION_INDEX';

const ORTHOSTATIC_BLOOD_PRESSURE_TYPE = 'ORTHOSTATIC_BLOOD_PRESSURE';

const WEEK_DAYS: readonly WeekDay[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const measurementTechTypes = ['TECH_BATTERY', 'TECH_LIVENESS', 'TECH_POWERSTATUS', 'CRADLEPOINT_LIVENESS'];

const ALWAYS_FLEXIBLE_FREQUENCIES = ['UNKNOWN', 'PO', 'CONT'];

const USER_LOGS_PATH_NAMES = {
    [routes.PATIENTS_CENSUS]: 'Census page',
    [routes.ADMINISTRATE_KITS]: 'Kits',
    [routes.ACTION_QUEUE]: 'Action queue',
    [routes.MESSAGE_HISTORY]: 'EHR message history',
    [routes.USER_ACCESS_LOG]: 'User Access log',
    [routes.MULTI_PATIENT_APPOINTMENTS_CALENDAR]: 'Calendar',
    [routes.PROVIDER_VIEW_APPOINTMENTS_CALENDAR()]: 'Calendar',
    '/my-page/:nurseId/my-patients': 'My page',
    'last-patients': 'Last 15 charts',
} as const;

const CANCELED_EXCEPT_TBD = 'CANCELED_EXCEPT_TBD';
const WAITING_CANCEL_ACKNOWLEDGE = 'WAITING_CANCEL_ACKNOWLEDGE';
const WAITING_UPDATE_ACKNOWLEDGE = 'WAITING_UPDATE_ACKNOWLEDGE';
const CANCELED_WAITING_ACTIVITIES_COMPLETION = 'CANCELED_WAITING_ACTIVITIES_COMPLETION';
const CANCELLED = 'CANCELLED';

const appointmentTypeIcons = {
    IN_HOME: <HomeOutlinedIcon />,
    VIDEO_VISIT: <VideocamOutlinedIcon />,
    PHONE_CALL: <PhoneOutlinedIcon />,
    VITAL_SIGNS: <NotificationsNoneOutlinedIcon />,
    PATIENT_REMINDER_MESSAGE: <NotificationsNoneOutlinedIcon />,
    FACILITY_VISIT: <BusinessOutlinedIcon />,
    OTHER: <CalendarTodayOutlinedIcon />,
} as const;

const PREVENT_LEAVE_WARNING_MESSAGE = 'You have unsaved changes. Are you sure you want to leave?';

const SPI_ACTION_STATUS = {
    IN_PROGRESS: 'In progress',
    DONE: 'Completed',
    FAILED: 'Failed',
} as const;

enum SPI_ACTIONS {
    SEND_REFERRAL_DOCUMENTATION = 'SEND_REFERRAL_DOCUMENTATION',
    SEND_APPOINTMENT_REQUEST = 'SEND_APPOINTMENT_REQUEST',
}

const SPI_RADIO_BUTTON_INFO = {
    SEND_REFERRAL_DOCUMENTATION: {
        title: 'TridentCare Interface',
        content: 'Allows sending referral documents to this vendor site from a service request',
    },
    SEND_APPOINTMENT_REQUEST: {
        title: 'GMR Interface ',
        content:
            'Allows sending appointment request along with referral documents to this vendor site from a service request',
    },
} as const;

const SPI_ACTIONS_INFO = {
    SEND_REFERRAL_DOCUMENTATION: {
        buttonText: 'Send Referral Documentation',
        statusText: 'Referral document status',
    },
    SEND_APPOINTMENT_REQUEST: {
        buttonText: 'Send Appointment Request',
        statusText: 'Appointment request status',
    },
} as const;

enum PERFORMANCE_IMPROVEMENT_LOG_STATUS {
    DRAFT = 'Draft',
    READY_FOR_REVIEW = 'Ready for review',
    IN_REVIEW = 'In review',
    CLOSED = 'Closed',
}

enum ACTION_QUEUE_SERVICE_REQUESTS {
    HANDOFF = 'HANDOFF',
    CHANGE_VENDOR = 'CHANGE_VENDOR',
    APPOINTMENT_CANCELLATION = 'APPOINTMENT_CANCELLATION',
    DOCUMENTATION = 'DOCUMENTATION',
    APPOINTMENT_MODIFICATION = 'APPOINTMENT_MODIFICATION',
}

const DAYS_SORTER: {[key: string]: number} = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7,
};

const CRITERIA_TYPES_NAME = {
    GT: 'Greater Than',
    GT_OR_EQ: 'Greater Than',
    LT: 'Less Than',
    LT_OR_EQ: 'Less Than',
    EQ: 'Equals',
    ABSCHANGE: 'Absolute Change',
    PCTCHANGE: 'PCT change',
    DISCRETE_INCREASE: 'Discrete Increase',
    DISCRETE_DECREASE: 'Discrete Decrease',
    RELATIVE_DECREASE: 'Relative Decrease',
    INCREASE_OVERTIME: 'Increase Overtime',
    DECREASE_OVERTIME: 'Decrease Overtime',
    PERCENTAGE_INCREASE_OVERTIME: 'Percentage Increase Overtime',
    PERCENTAGE_DECREASE_OVERTIME: 'Percentage Decrease Overtime',
} as const;

const MEASUREMENT_TYPES: Record<string, string> = {
    SYSTOLIC: 'Blood Pressure (Systolic & Diastolic)',
    DIASTOLIC: 'Blood Pressure (Systolic & Diastolic)',
    SPO2: 'Oxygen Saturation',
    PULSE: 'Heart Rate',
    PAINSCALE: 'Pain Scale',
    BLOOD_GLUCOSE: 'Blood Glucose',
    TEMPF: 'Body Temperature (C & F)',
    TEMPC: 'Body Temperature (C & F)',
    WEIGHT_LBS: 'Body Weight',
    RESPIRATORY_RATE: 'Respiratory Rate',
    RESPIRATORY_RATE_AT_REST: 'Respiratory Rate at Rest',
    HEART_RATE_AT_REST: 'Heart Rate at Rest',
    SKIN_TEMPERATURE: 'Skin Temperature',
    TECH_BATTERY: 'Tech: Battery',
    TECH_PERS_BATTERY: 'Tech: PERS Battery',
    TECH_POWERSTATUS: 'Tech: Plugged in',
    TECH_LIVENESS: 'Tech: Liveness',
    CRADLEPOINT_LIVENESS: 'Tech: Cradlepoint Liveness',
    ORTHOSTATIC_BLOOD_PRESSURE: 'Orthostatic Blood Pressure',
    SYSTOLIC_SEATED_VS_RECLINING: 'Orthostatic Blood Pressure',
    SYSTOLIC_STANDING_VS_SEATED: 'Orthostatic Blood Pressure',
    DIASTOLIC_SEATED_VS_RECLINING: 'Orthostatic Blood Pressure',
    DIASTOLIC_STANDING_VS_SEATED: 'Orthostatic Blood Pressure',
};

const VIDEO_IS_FILTER_SELECTED = 'VIDEO_IS_FILTER_SELECTED';
const VIDEO_FILTER_OPTION = 'VIDEO_FILTER_OPTION';
const VIDEO_CUSTOM_BG_BASE64_SRC = 'VIDEO_CUSTOM_BG_BASE64_SRC';
const VIDEO_IS_CUSTOM_BG_SELECTED = 'VIDEO_IS_CUSTOM_BG_SELECTED';
const VIDEO_DEFAULT_CAMERA_ID = 'VIDEO_DEFAULT_CAMERA_ID';
const VIDEO_DEFAULT_MIC_ID = 'VIDEO_DEFAULT_MIC_ID';
const VIDEO_DEFAULT_SPEAKER_ID = 'VIDEO_DEFAULT_SPEAKER_ID';
enum CarePlanningUpdatedModalActionOnClose {
    Refresh,
    GoToFirstStep,
}

enum CarePlanningSteps {
    PLAN_CARE,
    REVIEW,
}

export {
    ACTION_QUEUE_SERVICE_REQUESTS,
    ACTIONS,
    ACTIVITY_STATES,
    ACTIVITY_STATUSES,
    ALWAYS_FLEXIBLE_FREQUENCIES,
    appointmentsStates,
    appointmentsStatus,
    appointmentTypeIcons,
    appointmentTypes,
    BLOCKING_CODES,
    CALENDAR_LICENSE_KEY,
    CANCELED_EXCEPT_TBD,
    CANCELED_WAITING_ACTIVITIES_COMPLETION,
    CANCELLED,
    CarePlanningSteps,
    CarePlanningUpdatedModalActionOnClose,
    constants,
    CRITERIA_TYPES_NAME,
    DAYS_SORTER,
    DEFAULT_DEVICES_QUERY_PARAMS,
    DEFAULT_PAGINATION,
    DEVICE_FILTER_TYPES,
    DEVICE_STATUSES,
    EDIT_HIDDEN_STATUSES,
    FIELD_TYPES,
    KIT_ASSIGNMENT_STATUSES,
    MEASUREMENT_TYPES,
    measurementTechTypes,
    ORTHOSTATIC_BLOOD_PRESSURE_TYPE,
    PERFORMANCE_IMPROVEMENT_LOG_STATUS,
    PREVENT_LEAVE_WARNING_MESSAGE,
    SPI_ACTION_STATUS,
    SPI_ACTIONS,
    SPI_ACTIONS_INFO,
    SPI_RADIO_BUTTON_INFO,
    STATUSES,
    surveysState,
    TASKS_STATUSES,
    TASKS_TYPES,
    themes,
    USER_LOGS_PATH_NAMES,
    VENDOR_DICTIONARY_CONTACT_PRIORITY,
    VENDOR_DICTIONARY_NEW_ID,
    VENDOR_DICTIONARY_TYPES,
    VIDEO_CUSTOM_BG_BASE64_SRC,
    VIDEO_DEFAULT_CAMERA_ID,
    VIDEO_DEFAULT_MIC_ID,
    VIDEO_DEFAULT_SPEAKER_ID,
    VIDEO_FILTER_OPTION,
    VIDEO_IS_CUSTOM_BG_SELECTED,
    VIDEO_IS_FILTER_SELECTED,
    VITAL_SIGNS_BLOOD_PRESSURE_TYPE,
    VITAL_SIGNS_PERFUSION_TYPE,
    WAITING_CANCEL_ACKNOWLEDGE,
    WAITING_UPDATE_ACKNOWLEDGE,
    WEEK_DAYS,
};
